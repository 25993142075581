import { IPage } from "data/contentData/api/content"

export default function getContentIds(page: IPage) {
    const ids = {
        regionalContentId: "",
        authorsContentId: "",
        referencesContentId: ""
    }

    if (page.sections) {
        ids.regionalContentId =
            page.sections.find(p => p.type === "regional")?.url ?? ""
    }

    if (page.sources) {
        ids.authorsContentId = `${page.sources.url}-authors`
        ids.referencesContentId = `${page.sources.url}-references`
    }

    return ids
}

import { forwardRef } from "react";
import Typography, { TypographyProps } from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { slugify } from "lib/string/slugify";
interface TitleProps extends TypographyProps {
  level?: number;
}
const variants = ["h1", "h2", "h3", "h4", "h5", "h6"] as const;
export const Title = forwardRef<HTMLSpanElement, TitleProps>(function Title({
  level = 1,
  id,
  children,
  ...props
}, ref) {
  id = slugify(id || children as string);
  const variant = variants[level - 1] || "body1";
  return <StyledTitle ref={ref} variant={variant} id={id} {...props}>
            {children}
        </StyledTitle>;
});
const StyledTitle = styled(Typography)(({
  theme
}) => ({
  padding: theme.spacing(1, 0),
  scrollMarginTop: `${theme.constants.height.header + theme.constants.height.headerXSExtra + 16}px`,
  [theme.breakpoints.up("sm")]: {
    scrollMarginTop: `${theme.constants.height.header + 16}px`
  },
  [theme.breakpoints.up("md")]: {
    scrollMarginTop: `${theme.constants.height.header + theme.constants.height.breadcrumbs + 16}px`
  }
}));
/**
 * Check if page is outdated based on published, revised and revision interval
 * @param interval number of days until a revision is required
 * @param published date for when the page was published
 * @param revised date for when the page was revised
 * @returns boolean
 */
export function isOutdated(
    interval?: number,
    published?: Date,
    revised?: Date
): boolean {
    // CMS API defines published as nullable ... which makes little sence: treat this case as not outdated
    if (published && interval) {
        const latestDate = new Date(revised ?? published)
        const revisionInterval = interval * 24 * 60 * 60 * 1000
        const dueRevision = latestDate.valueOf() + revisionInterval
        return dueRevision < Date.now()
    }

    // Treat as never outdated, if falsy
    return false
}

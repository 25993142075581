import useUser from "hooks/useUser";
import { t } from "locales";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { isEditor } from "auth/authorization";
import { isOutdated } from "lib/cms/is-outdated";
import { toLocalDate } from "lib/string/date-to-locale-date-string";
interface DateTextProps {
  name: "last-approved-by" | "last-revised" | "last-updated" | "published";
  value?: Date | string;
  className?: string;
}
function DateText({
  name,
  value
}: DateTextProps) {
  if (!value) {
    return null;
  }
  return <Typography variant="caption" data-sentry-element="Typography" data-sentry-component="DateText" data-sentry-source-file="DateText.tsx">
            {`${t[name]}: `}
            <time dateTime={value.toString()}>
                {toLocalDate(value, process.env.NEXT_PUBLIC_LOCALE)}
            </time>
        </Typography>;
}
interface IProps {
  updated?: Date;
  revised?: Date;
  published?: Date;
  revisionInterval?: number;
}
export function DateTexts(props: IProps) {
  const {
    user
  } = useUser();
  return <StyledContainer container data-sentry-element="StyledContainer" data-sentry-component="DateTexts" data-sentry-source-file="DateText.tsx">
            {isEditor(user?.roles) ? <EditorialDates {...props} /> : <NormalDates {...props} />}
        </StyledContainer>;
}
const StyledContainer = styled(Grid)(({
  theme
}) => {
  const {
    spacing
  } = theme;
  return {
    display: "flex",
    justifyContent: "space-between",
    gap: spacing(1)
  };
});
function NormalDates({
  updated,
  revised,
  published
}: IProps) {
  const dates = getDates(updated, revised, published);
  return <>
            {dates.map(date => <DateText key={date.name} name={date.name} value={date.value} />)}
        </>;
}
function EditorialDates({
  updated,
  revised,
  published,
  revisionInterval
}: IProps) {
  const outdated = isOutdated(revisionInterval, published, revised);
  return <>
            <DateText name="published" value={published} data-sentry-element="DateText" data-sentry-source-file="DateText.tsx" />

            <DateText name="last-updated" value={updated} data-sentry-element="DateText" data-sentry-source-file="DateText.tsx" />
            {outdated && revised ? <StyledChip size="small" label={<DateText name="last-revised" value={revised} />} /> : <DateText name="last-revised" value={revised} />}

            <Typography variant="caption" data-sentry-element="Typography" data-sentry-source-file="DateText.tsx">
                {"Revision interval:"} {revisionInterval}
            </Typography>
        </>;
}
const StyledChip = styled(Chip)(({
  theme
}) => ({
  backgroundColor: theme.palette.error.main,
  color: theme.palette.common.white
}));

/**
 * Compute which dates to display according to some weird rules...
 */
function getDates(updated?: Date, revised?: Date, published?: Date) {
  function dateMS(date?: Date) {
    return date ? new Date(date).getTime() : 0;
  }
  const dates: DateTextProps[] = [];
  if (dateMS(updated) > dateMS(revised) && dateMS(updated) > dateMS(published)) {
    dates.push({
      name: "last-updated",
      value: updated
    });
  }
  if (dateMS(revised) > dateMS(published)) {
    dates.push({
      name: "last-revised",
      value: revised
    });
  } else {
    dates.push({
      name: "published",
      value: published
    });
  }
  return dates;
}